
import { defineComponent } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "house-building-policy-information-content-component",
  components: {},
  props: {
    title: String,
    image: String,
    items: Array,
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
